body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  /* font-family: Arial, Helvetica, sans-serif */
}

/* a {
  text-decoration: none;
  color: 'FFF'
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fixed-wrapper {
  position: fixed;
  top: 0;
  height:100vh;
  background-color: #000;
}

.headline-background {
  background-color: #161616;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 64px;
}

.headline-background h1 {
  margin: 0;
  line-height: 16px;
  font-size: 4em;
}

.headline-background h3 {
  margin: 0;
  line-height: 16px;
}

.work_videos {
  width: 100%;
  height: 100%;
}

.overlayss {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
}

.contents {
  position: relative;
  width: 100%;
  margin: 0 auto;
  /* padding: 20px; */
}

.contents:before {
  content: '';
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  display: block;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2vw;
}

h1 {
  margin: 0;
}



.gallery {
  margin-top: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* gap: 10px;  */
}

.gallery-press {
  margin-top: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* gap: 10px;  */
}


.gallery-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 0; /* Adjust margin as needed */
}


/* FOOTER */

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #888;
  margin: 24px 0;
}



/* WORK IMG */

.picture-container {
  position: relative;
  display: inline-block;
  font-size: 0;
  line-height: 0;
}

.overlay-work-gallary {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(180, 154, 154, 0.5); /* Gray transparent overlay */
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  line-height: 20px;
}

.overlay-gallary {
  position: absolute;
  bottom: 0;
  /* left: 0; */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Gray transparent overlay */
  /* padding: 8px; */
  /* box-sizing: border-box; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  transition: 0.3s ease;
  /* line-height: 20px; */
}

.title-gallary {
  color: white;
  font-size: 18px;
}

.picture {
  width: 100%;
  height: auto;
}

.picture:hover {
  cursor: pointer;
}

/* WORKPOST */

.workpost {
  text-align: left;
  line-height: 35px;
  font-size: 1.2em;
  margin: 10vh 20vw 5vh 20vw;
}

.workpost h1 {
  line-height: 50px;
}

.workpost h2 {
  margin: 0px;
}

.boldAndLarge {
  font-weight: bold;
  font-size: 1.5em;
}

.prevWorkPostButton {
  line-height: 0;
  position: fixed;
  bottom: 49vh;
  left: 25px;
  background-color: rgba(255, 255, 255, 0.5);
  color: #000;
  border: none;
  border-radius: 50%;
  padding: 10px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.prevWorkPostButton:hover {
  background-color: rgba(180, 154, 154, 0.5);
  color: #FFF
}

.nextWorkPostButton {
  line-height: 0;
  position: fixed;
  bottom: 49vh;
  right: 25px;
  background-color: rgba(255, 255, 255, 0.5);
  color: #000;
  border: none;
  border-radius: 50%;
  padding: 10px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nextWorkPostButton:hover {
  background-color: rgba(180, 154, 154, 0.5);
  color: #FFF
}

/* YOUTUBE VIDEO Responsive */

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* CLIENTS */

.clients-list {
  font-size: 1.8em;
  text-align: justify;
  hyphens: auto; 
  line-height: initial;
  word-wrap: break-word;
}

/* Scroll To Top Button */

.scrollToTopButton {
  line-height: 0;
  position: fixed;
  bottom: 25px;
  right: 25px;
  background-color: rgba(255, 255, 255, 0.5);
  color: #000;
  border: none;
  border-radius: 50%;
  padding: 10px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scrollToTopButton svg {
  font-size: 2em;
}

.scrollToTopButton:hover {
  background-color: rgba(180, 154, 154, 0.5);
  color: #FFF
}


/* STUDIO */
